import React from "react"

import { Typography } from "@material-ui/core"
import { Helmet } from "react-helmet"

import MainShell from "../../components/main-shell"
import OrderDetails from "../../components/order-details"
import CheckoutStepper from "../../components/checkout-stepper"

const OrderDetailsPage = () => (
  <MainShell>
    <Helmet>
      <title>Gegevens - Todocards.nl</title>
      <meta name="robots" content="noindex, follow" />
    </Helmet>

    <div className="mb2">
      <CheckoutStepper currentStep={2} />
    </div>

    <Typography variant="h1" className="mb1">
      Gegevens
    </Typography>

    <OrderDetails />
  </MainShell>
)

export default OrderDetailsPage
