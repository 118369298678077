import React from "react"

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core"

import { Controller, FieldError, useFormContext } from "react-hook-form"

import { ViewProps } from "./select-field-types"

const SelectFieldView = ({ name, label, options }: ViewProps) => {
  const { control, errors } = useFormContext()
  return (
    <Box mb={2}>
      <FormControl variant="outlined" error={!!errors[name]} fullWidth>
        <InputLabel>{label}</InputLabel>

        <Controller
          name={name}
          id={name}
          control={control}
          as={
            <Select
              label={label}
              disabled={control.formState.isSubmitting}
              margin="dense"
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          }
        />

        {errors[name] && (
          <FormHelperText>
            {(errors[name] as FieldError).message}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  )
}

export default SelectFieldView
