import { connect } from "react-redux"

import { State } from "../../../redux/types"
import { ViewProps } from "./cart-summary-types"
import CartSummaryView from "./cart-summary-view"
import getCartData from "../../../utils/get-cart-data"
import { Country } from "../../../api/sync"

const mapStateToProps = (
  state: State,
  props: { shippingCountry: Country }
): ViewProps => {
  return {
    totalQuantity: state.cart.reduce(
      (count, cartRecord) => count + cartRecord.quantity,
      0
    ),
    ...getCartData<{ title: string }>(state.cart, props.shippingCountry, [
      `title`,
    ]),
  }
}

export default connect(mapStateToProps)(CartSummaryView)
