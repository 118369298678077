import React from "react"

import { Grid } from "@material-ui/core"

import { Country } from "../../../api/sync"
import { countryData } from "../../../data/order"
import SelectField from "../../utils/form/select-field"
import TextField from "../../utils/form/text-field"
import { ViewProps } from "./address-types"

const AddressView = ({ prefix }: ViewProps) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField name={`${prefix}FirstName`} label="Voornaam" />
        </Grid>

        <Grid item xs={6}>
          <TextField name={`${prefix}LastName`} label="Achternaam" />
        </Grid>
      </Grid>

      <TextField
        name={`${prefix}CompanyName`}
        label="Bedrijfsnaam (optioneel)"
      />

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField name={`${prefix}Street`} label="Straat" />
        </Grid>

        <Grid item xs={4}>
          <TextField name={`${prefix}StreetNumber`} label="Nummer" />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField name={`${prefix}PostalCode`} label="Postcode" />
        </Grid>

        <Grid item xs={8}>
          <TextField name={`${prefix}City`} label="Plaats" />
        </Grid>
      </Grid>

      <SelectField
        name={`${prefix}Country`}
        label="Land"
        options={(Object.values(Country) as Country[]).map(country => ({
          value: country,
          label: countryData[country].label,
        }))}
      />
    </>
  )
}

export default AddressView
