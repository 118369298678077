import { connect } from "react-redux"

import OrderDetailsView from "./order-details-view"
import { State } from "../../redux/types"
import { ViewProps } from "./order-details-types"
import { showAlert } from "../../redux/alert/actions"
import { setDetails, setRef } from "../../redux/order/actions"

const mapStateToProps = (state: State): ViewProps => {
  return { cart: state.cart, formValues: state.order.details }
}

export default connect(mapStateToProps, { showAlert, setDetails, setRef })(
  OrderDetailsView
)
