import yup from "../../utils/get-yup"

const validateInvoice = yup.string().when(`invoiceSameAsShipping`, {
  is: false,
  then: yup.string().required(),
})

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required()
    .email(),
  paymentMethod: yup.string().required(),
  shippingFirstName: yup.string().required(),
  shippingLastName: yup.string().required(),
  shippingStreet: yup.string().required(),
  shippingStreetNumber: yup.string().required(),
  shippingPostalCode: yup.string().required(),
  shippingCity: yup.string().required(),
  shippingCountry: yup.string().required(),
  invoiceFirstName: validateInvoice,
  invoiceLastName: validateInvoice,
  invoiceStreet: validateInvoice,
  invoiceStreetNumber: validateInvoice,
  invoicePostalCode: validateInvoice,
  invoiceCity: validateInvoice,
  invoiceCountry: validateInvoice,
})
