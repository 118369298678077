import React from "react"

import { Checkbox, FormControlLabel } from "@material-ui/core"
import { Controller, useFormContext } from "react-hook-form"

import { ViewProps } from "./checkbox-types"

const CheckboxView = ({ name, label }: ViewProps) => {
  const { control } = useFormContext()
  return (
    <Controller
      as={
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label={label}
          disabled={control.formState.isSubmitting}
        />
      }
      id={name}
      name={name}
      control={control}
    />
  )
}

export default CheckboxView
