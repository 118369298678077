import React from "react"

import { Box, Divider, Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import clsx from "clsx"
import { Link as GatsbyLink } from "gatsby"

import { ViewProps } from "./cart-summary-types"
import CentsToEuro from "../../../utils/cents-to-euro"

const useStyles = makeStyles(theme => ({
  quantity: {
    width: `1px`,
    whiteSpace: `nowrap`,
    paddingRight: theme.spacing(1),
  },

  success: {
    color: theme.palette.success.main,
  },
}))

const CartSummaryView = ({
  products,
  totalPrice,
  totalQuantity,
  shippingCosts,
}: ViewProps) => {
  const classes = useStyles()

  return (
    <Typography component="div">
      <table width="100%" className="mb05">
        {products.map(product => (
          <tr key={product.id}>
            <td className={`bold ${classes.quantity}`}>{product.quantity} x</td>
            <td>{product.title}</td>
          </tr>
        ))}
      </table>

      <Typography variant="body2" className="mb2">
        <Link
          component={GatsbyLink}
          underline="always"
          to="/bestellen/winkelwagen"
        >
          Winkelwagen wijzigen
        </Link>
      </Typography>

      <Divider className="mb2" />

      <Box display="flex" justifyContent="space-between">
        <span>
          Totaal (
          {totalQuantity === 1 ? `1 product` : `${totalQuantity} producten`})
        </span>
        <span>{CentsToEuro(totalPrice - shippingCosts)}</span>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <span>Verzending</span>
        <span
          className={clsx(
            shippingCosts === 0 && `bold`,
            shippingCosts === 0 && classes.success
          )}
        >
          {shippingCosts === 0 ? `Gratis` : CentsToEuro(shippingCosts)}
        </span>
      </Box>

      <Box display="flex" justifyContent="space-between" className="bold">
        <span>Totaalprijs</span>
        <span>{CentsToEuro(totalPrice)}</span>
      </Box>
    </Typography>
  )
}

export default CartSummaryView
