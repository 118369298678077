import React, { useEffect } from "react"

import { Box, Collapse, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { useForm, FormContext } from "react-hook-form"
import { navigate } from "gatsby"

import { OrderDetails } from "../../redux/order/types"
import { PaymentMethod } from "../../api/sync"
import { createOrder } from "../../api/actions"
import { paymentMethodData } from "../../data/order"

import SubmitButton from "../utils/form/submit-button"
import TextField from "../utils/form/text-field"
import SelectField from "../utils/form/select-field/select-field-view"
import Checkbox from "../utils/form/checkbox"

import { validationSchema } from "./order-details-validation"
import Address from "./address"
import CartSummary from "./cart-summary"
import { ViewDispatchActions, ViewProps } from "./order-details-types"

const useStyles = makeStyles(theme => ({
  hideMd: {
    display: `none`,
    [theme.breakpoints.up(`md`)]: {
      display: `block`,
    },
  },

  showMd: {
    display: `block`,
    [theme.breakpoints.up(`md`)]: {
      display: `none`,
    },
  },
}))

const OrderDetailsView = ({
  cart,
  formValues,
  showAlert,
  setDetails,
  setRef,
}: ViewProps & ViewDispatchActions) => {
  useEffect(() => {
    if (cart.length === 0) {
      navigate(`/bestellen/winkelwagen`)
    }
  }, [])

  if (cart.length === 0) {
    return <></>
  }

  const classes = useStyles()
  const methods = useForm<OrderDetails>({
    defaultValues: formValues,
    mode: `onBlur`,
    validationSchema,
  })

  const onSubmit = async (details: OrderDetails) => {
    setDetails(details)

    await createOrder(
      details,
      cart,
      `${process.env.GATSBY_DOMAIN}/bestellen/betalen`,
      `De betaling kon niet gestart worden, er is een onbekende fout opgetreden.`,
      `De betaling kon niet gestart worden, controleer de verbinding met internet.`
    ).then(response => {
      setRef(response.ref)

      // Needed to make sure the ref is saved into localStorage
      setTimeout(() => {
        location.assign(response.checkoutUrl)
      }, 1000)
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    methods.triggerValidation().then(isValid => {
      if (!isValid) {
        showAlert(
          `Er is een fout opgetreden`,
          `De betaling kon niet gestart worden omdat niet alle velden juist zijn ingevuld.`
        )
      }

      methods.handleSubmit(onSubmit)()
    })
  }

  const wachtShippingCountry = methods.watch(`shippingCountry`)
  const watchInvoiceSameAsShipping = methods.watch(`invoiceSameAsShipping`)

  const cartSummary = (
    <Box p={2} bgcolor="grey.100" borderRadius="borderRadius">
      <Typography variant="h3" gutterBottom>
        Winkelwagen
      </Typography>
      <CartSummary shippingCountry={wachtShippingCountry} />
    </Box>
  )

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <TextField name="email" label="E-mailadres" />

            <SelectField
              name={`paymentMethod`}
              label="Betaalmethode"
              options={(Object.values(PaymentMethod) as PaymentMethod[]).map(
                paymentMethod => ({
                  value: paymentMethod,
                  label: paymentMethodData[paymentMethod].label,
                })
              )}
            />

            <Typography variant="h3" gutterBottom>
              Afleveradres
            </Typography>

            <Address prefix="shipping" />

            <Typography variant="h3" gutterBottom>
              Factuuradres
            </Typography>

            <Box mb={1}>
              <Checkbox
                name="invoiceSameAsShipping"
                label="Hetzelfde als afleveradres"
              />
            </Box>

            <Collapse in={!watchInvoiceSameAsShipping}>
              <Address prefix="invoice" />
            </Collapse>

            <div className={`mb2 ${classes.showMd}`}>{cartSummary}</div>

            <SubmitButton
              label="Betalen"
              isSubmitting={methods.formState.isSubmitting}
            />
          </Grid>

          <Grid item xs={12} md={4} className={classes.hideMd}>
            {cartSummary}
          </Grid>
        </Grid>
      </form>
    </FormContext>
  )
}

export default OrderDetailsView
